(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccessDoorContainerController', AccessDoorContainerController);

    AccessDoorContainerController.$inject = ['$localStorage', '$mdToast', '$timeout', 'Auth', '$state', '$scope', '$rootScope', 'House', 'globalCompany', 'Destinies', 'Emergency', 'WSEmergency', 'WSGate', 'WSNote', 'WSHouse', 'WSVisitorInvitation', 'Modal', 'CommonMethods', 'Principal'];

    function AccessDoorContainerController($localStorage, $mdToast, $timeout, Auth, $state, $scope, $rootScope, House, globalCompany, Destinies, Emergency, WSEmergency, WSGate, WSNote, WSHouse, WSVisitorInvitation, Modal, CommonMethods, Principal) {
        var vm = this;
        $rootScope.mainTitle = "Puerta de Acceso";
        $rootScope.emergencyList = [];
        $rootScope.notes = [];
        $rootScope.houseNoteNotification = undefined;
        $rootScope.visitorHouseNotification = undefined;
        $rootScope.houseSelectedNote = -1;
        $rootScope.isMacroCondo = false;
        $rootScope.deletedStatusNote = 0;
        vm.sound_url = 'https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/waiting.mp3?alt=media&token=45ad848c-812a-4fa7-98ed-4b9a36206d96';
        $rootScope.audio = new Audio(vm.sound_url);
        $rootScope.audio.loop = true;
        $rootScope.audio.load();



        $rootScope.companyId = globalCompany.getId();
        if (!$localStorage.visitorQrRequest) {
            $localStorage.visitorQrRequest = [];
        }else{
            if($localStorage.visitorQrRequest.length > 0){
                $rootScope.audio.play();
            }
        }
        Principal.identity().then(function (account) {
            $rootScope.loginAccount = account.login;
        });

        var states = ["access-door.houses", "access-door.register-visitor", "access-door.visitant-admin", "access-door.common-area-all-reservations", "access-door.notes"];
        var posState = 0;

        switch ($state.current.name) {
        case "access-door.houses":
            posState = 0;
            break;
        case "access-door.register-visitor":
            posState = 1;
            break;
        case "access-door.visitant-admin":
            posState = 2;
            break;
        case "access-door.common-area-all-reservations":
            posState = 3;
            break;
        case "access-door.notes":
            posState = 4;
            break;
        }

        $rootScope.visitorInvited = [];
        $rootScope.notes = [];

        function processHouses(houses) {
            var companiesMap = {};

            houses.forEach(function(house) {
                if (house.housenumber.indexOf('-->') !== -1) {
                    var parts = house.housenumber.split('-->');
                    var companyName = parts[0].trim();
                    var houseName = parts[1].trim();

                    if (!companiesMap[companyName]) {
                        companiesMap[companyName] = {
                            name: companyName,
                            houses: []
                        };
                    }

                    var houseCopy = {};
                    for (var key in house) {
                        if (house.hasOwnProperty(key)) {
                            houseCopy[key] = house[key];
                        }
                    }
                    houseCopy.housenumber = houseName;

                    companiesMap[companyName].houses.push(houseCopy);
                }
            });

            // Convertir el objeto en un arreglo
            var companies = [];
            for (var key in companiesMap) {
                if (companiesMap.hasOwnProperty(key)) {
                    companies.push(companiesMap[key]);
                }
            }

            return companies;
        }

        function verifyAndProcessHouses(houses) {
            if (houses.length > 0 && houses[0].housenumber.indexOf('-->') !== -1) {
                return processHouses(houses);
            } else {
                console.log("La primera casa no contiene '-->' en el housenumber.");
                return [];
            }
        }

        House.getAllHousesCleanAccessDoor({companyId: globalCompany.getId()}, function (data) {
            $rootScope.houses = data;
            $rootScope.macroCondos = verifyAndProcessHouses($rootScope.houses);
            if($rootScope.macroCondos.length!=0){
                $rootScope.condoSelected = $rootScope.macroCondos[0];
                $rootScope.houses = $rootScope.condoSelected.houses;
                $rootScope.isMacroCondo = true;
            }
            subscribe();
        });
        Destinies.query(function (destinies) {
            $rootScope.destinies = destinies;
        });
        // $scope.$on("$destroy", function () {
        //     unsubscribe();
        // });
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-center",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };

        function unsubscribe() {
            WSDeleteEntity.unsubscribe(globalCompany.getId());
            WSEmergency.unsubscribe(globalCompany.getId());
            WSHouse.unsubscribe(globalCompany.getId());
            WSResident.unsubscribe(globalCompany.getId());
            WSVehicle.unsubscribe(globalCompany.getId());
            WSNote.unsubscribe(globalCompany.getId());
            WSVisitor.unsubscribe(globalCompany.getId());
            WSOfficer.unsubscribe(globalCompany.getId());
            WSGate.unsubscribe(globalCompany.getId());
        }

        vm.logout = function () {
            Auth.logout();
            switch (globalCompany.getUserRole()) {
            case "ROLE_OFFICER":
                // $timeout.cancel($rootScope.timerAd);
                unsubscribe();
                break;
            case "ROLE_OFFICER_MACRO":
                // $timeout.cancel($rootScope.timerAd);
                unsubscribe();
                break;
            }
            $localStorage.houseSelected = undefined;
            $rootScope.companyUser = undefined;
            $state.go('home');
            $rootScope.menu = false;
            $rootScope.companyId = undefined;
            $localStorage.companyName = undefined;
            $rootScope.companyConfigsLoaded = false;
            $rootScope.showLogin = true;
            $rootScope.inicieSesion = false;
        };
        vm.showKeys = function () {
            Modal.customDialog("<md-dialog>" +
                "<md-dialog-content class='md-dialog-content text-center'>" +
                "<h1 class='md-title'>Número de soporte </h1>" +
                "<div class='md-dialog-content-body'>" +
                "<p>En caso de necesitar ayuda o el sistema le presenta un problema, favor comunicarse al <b>8935-2994</b> o <b>8934-0760</b></p>" +
                "<h3 style='text-align:center'><img height='60' src ='content/images/poweredByAditum.png'></h3>" +
                "</div>" +
                "</md-dialog-content>" +
                "</md-dialog>");
        };
        loadEmergencies();

        function existItem(array, item) {
            var founded = false;
            angular.forEach(array, function (item, i) {
                if (parseInt(item.id) === parseInt(item.id)) {
                    founded = true;
                }
            });
            return founded;
        }

        function loadEmergencies() {
            Emergency.findAll({
                companyId: globalCompany.getId()
            }, onSuccessEmergencies, onError);

            function onSuccessEmergencies(emergencies, headers) {
                if (emergencies.length > 0) {
                    vm.emergency = undefined;
                    receiveEmergency(emergencies[0]);
                    $rootScope.emergencyList = emergencies;
                } else {
                    $rootScope.emergencyList = [];
                }
            }

            function onError() {
                vm.show = 4;
                vm.hideRegisterForm = 2;
                vm.hideLoadingForm = 1;
                $rootScope.emergencyList = [];
            }
        }

        $rootScope.houseSelected = -1;
        var delay = 1000;
        $rootScope.online = true;
        var toastOffline;
        vm.emergencyInProgress = false;

        function receiveHomeService(note) {
            vm.showNotificationNote = true;
            // note.sinceDate = moment(note.creationdate).fromNow();
            // if ($rootScope.deletedStatusNote == 0) {
            //     if ($rootScope.houseSelectedNote == -1 && $rootScope.noteCreatedBy != 2) {
            //         if (existItem($rootScope.notes, note)) {
            //             $rootScope.notes.push(note);
            //         }
            //     }
            //     if ($rootScope.houseSelectedNote == note.houseId && $rootScope.noteCreatedBy != 2) {
            //         if (existItem($rootScope.notes, note)) {
            //             $rootScope.notes.push(note);
            //         }
            //     }
            // }
            Modal.actionToastGiantStay("¡Se ha recibido una nueva nota de una filial!", "Ver detalle", function () {
                // $rootScope.houseNoteNotification = note.house.id;
                vm.showNotificationNote = false;
                $state.go("access-door.notes");
            });
        }

        function receiveEmergency(emergency) {
            if (emergency.isAttended == 0) {
                vm.emergency = emergency;
                vm.emergencyInProgress = true;
            }
        }

        function unsubscribe() {
            // WSDeleteEntity.unsubscribe(globalCompany.getId());
            WSEmergency.unsubscribe(globalCompany.getId());
            WSHouse.unsubscribe(globalCompany.getId());
            // WSResident.unsubscribe(globalCompany.getId());
            // WSVehicle.unsubscribe(globalCompany.getId());
            WSNote.unsubscribe(globalCompany.getId());
            WSVisitorInvitation.unsubscribe(globalCompany.getId());
            WSGate.unsubscribe(globalCompany.getId());
            // WSVisitor.unsubscribe(globalCompany.getId());
            // WSOfficer.unsubscribe(globalCompany.getId());
        }

        function subscribe() {
            unsubscribe();
            setTimeout(function () {
                WSEmergency.subscribe(globalCompany.getId());
                WSHouse.subscribe(globalCompany.getId());
                WSGate.subscribe(globalCompany.getId());
                // WSResident.subscribe(globalCompany.getId());
                // WSVehicle.subscribe(globalCompany.getId());
                WSVisitorInvitation.subscribe(globalCompany.getId());
                WSNote.subscribe(globalCompany.getId());
                // WSOfficer.subscribe(globalCompany.getId());
                // WSDeleteEntity.subscribe(globalCompany.getId());
                // WSDeleteEntity.receive().then(null, null, receiveDeletedEntity);
                WSEmergency.receive().then(null, null, receiveEmergency);
                WSHouse.receive().then(null, null, receiveHouse);
                // WSResident.receive().then(null, null, receiveResident);
                // WSVehicle.receive().then(null, null, receiveVehicle);
                WSNote.receive().then(null, null, receiveHomeService);
                // WSOfficer.receive().then(null, null, receiveOfficer);
                WSVisitorInvitation.receive().then(null, null, receiveVisitorInvitation);
                WSGate.receive().then(null, null, receiveOpenedDoor);
                WSGate.receiveVisitorGate().then(null, null, receiveVisitorGate);
                WSGate.receiveQrRequestGate().then(null, null, receiveQrOpenGateRequest);
                WSGate.receiveQrCleanRequestGate().then(null, null, receiveClearQrOpenGateRequest);
                WSGate.receiveQrRequestGate().then(null, null, receiveQrOpenGateRequest);
            }, 3000);
        }

        function formatPlates(visitor) {
            var plates = [];
            if (visitor.licenseplate != undefined) {
                var lc = visitor.licenseplate.split("/");
                for (var i = 0; i < lc.length; i++) {
                    var plate = {licenseplate: lc[i].trim(), selected: false, valid: true};
                    plates.push(plate);
                }
                visitor.licenseplate = plates[0].licenseplate;
            }
            return plates;
        }

        function formatVisitantInvited(itemVisitor) {
            itemVisitor.plates = formatPlates(itemVisitor);
            if (itemVisitor.plates.length == 0) {
                itemVisitor.hasLicense = false;
            } else {
                itemVisitor.hasLicense = true;
            }
            if (itemVisitor.identificationnumber == null || itemVisitor.identificationnumber == undefined || itemVisitor.identificationnumber == "") {
                itemVisitor.hasIdentification = false;
            } else {
                itemVisitor.identificationnumber;
                itemVisitor.hasIdentification = true;
            }
            itemVisitor.validCed = true;
            itemVisitor.validPlate = true;
            itemVisitor.onTime = true;
            return itemVisitor;
            return null;
        }

        function receiveOpenedDoor(door) {
            Modal.actionToastGiantStay(door.concept, "Entendido", function () {
            });
        }

        function receiveVisitorGate(visitor) {
        }

        function receiveQrOpenGateRequest(visitor) {
            if (globalCompany.getId() != 201 || (globalCompany.getId() == 201 && $rootScope.loginAccount == 'bosqueslindora1')) {
                if (visitor.qrOpenGate != null) {
                    visitor.attended = false;
                    var exists = false;
                    for (var i = 0; i < $localStorage.visitorQrRequest.length; i++) {
                        if ($localStorage.visitorQrRequest[i].identificationnumber === visitor.identificationnumber) { // Compara por una propiedad única, como 'id'
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        $localStorage.visitorQrRequest.push(visitor);
                        $rootScope.audio.play();
                    }
                } else {
                    if (visitor.code != "PR") {
                        Modal.toastGiant(visitor.enterOrExit + " de autorizado mediante código QR");
                    } else {
                        Modal.toastGiant(visitor.enterOrExit + " de residente mediante código QR");
                    }
                }
            }
        }


        function receiveClearQrOpenGateRequest(visitor) {
            if ($localStorage.visitorQrRequest!=undefined && $localStorage.visitorQrRequest.length > 0) {
                var exists = false;
                var index = 0;
                for (var i = 0; i < $localStorage.visitorQrRequest.length; i++) {
                    if ($localStorage.visitorQrRequest[i].identificationnumber === visitor.identificationnumber) { // Compara por una propiedad única, como 'id'
                        exists = true;
                        index = i;
                        break;
                    }
                }
                if (exists) {
                    $localStorage.visitorQrRequest.splice(index, 1);
                    if($localStorage.visitorQrRequest.length == 0) {
                        $rootScope.audio.pause(); // Pausa la reproducción del audio
                        $rootScope.audio.currentTime = 0;
                    }
                }
            }
        }

        function receiveVisitorInvitation(visitor) {
            if (visitor.status == 1) {
                var title = "";
                var visitorI = formatVisitantInvited(visitor);
                if (visitor.houseNumber == null) {
                    title = "¡Se ha invitado un visitante en la oficina del administrador!";
                } else {
                    title = "¡Se ha invitado un visitante en la filial " + visitor.houseNumber + "!";
                }
                if (visitor.houseNumber == null) {
                    if ($rootScope.houseSelected == -1) {
                        var visitorI = formatVisitantInvited(visitor);
                        // if(!existItem($rootScope.visitorInvited,visitorI)){
                        $rootScope.visitorInvited.push(visitorI);
                        // }
                    }
                } else {
                    var visitorI = formatVisitantInvited(visitor);
                    // if(!existItem($rootScope.visitorInvited,visitorI)){
                    $rootScope.visitorInvited.push(visitorI);
                    // }
                }
                Modal.actionToastGiant(title, "Ver detalle", function () {
                    if (visitor.houseNumber == null) {
                        $rootScope.visitorHouseNotification = -1;
                    } else {
                        $rootScope.visitorHouseNotification = visitor.houseId;
                    }
                    $state.go("access-door.houses");
                });
            } else {
                setTimeout(function () {
                    $scope.$apply(function () {
                        CommonMethods.deleteFromArrayWithId(visitor, $rootScope.visitorInvited);
                    });
                }, 10);
            }
        }

        function receiveHouse(house) {
            House.getAllHousesClean({companyId: globalCompany.getId()}, function (data) {
                $rootScope.houses = data;
            });
        }

        vm.attendEmergency = function () {
            var codeEmegency = globalCompany.getId() + "" + vm.emergency.houseId;
            vm.emergency.isAttended = 1;
            Modal.toastGiant("Se ha reportado al residente que se atenderá la emergencia");
            Emergency.update(vm.emergency, function (result) {
                WSEmergency.sendActivityAttended(codeEmegency, vm.emergency);
                $timeout(function () {
                    vm.emergency = undefined;
                    vm.emergencyInProgress = false;
                    loadEmergencies();
                }, 5);
            });
        };

        Offline.options = {checks: {image: {url: '/content/images/LogoWebHor645x200.png'}, active: 'image'}};
        Offline.on('confirmed-down', function () {
            if ($rootScope.online) {
                toastOffline = $mdToast.show(
                    $mdToast.simple()
                        .textContent("Tu dispositivo perdió conexión a internet.")
                        .hideDelay(0)
                        .position("top center")
                );
                unsubscribe();
                $rootScope.online = false;
            }
        });

        Offline.on('confirmed-up', function () {
            if (!$rootScope.online) {
                $mdToast.hide();
                $mdToast.show(
                    $mdToast.simple()
                        .textContent("Tu dispositivo está conectado a internet nuevamente.")
                        .position("top center")
                );
                $rootScope.online = true;
                subscribe();
                // $state.go($state.current, {}, {reload: true});
            }
        });
        $rootScope.timerAd = $timeout(function retry() {
            Offline.check();
            $timeout(retry, delay);
        }, delay);
    }
})();
