(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ReceivingClientDetailController', ReceivingClientDetailController);

    ReceivingClientDetailController.$inject = ['Modal', 'globalCompany', 'CommonMethods', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ReceivingClient', 'Company', 'House', 'FiscalTerritorialDivision', 'ElectronicBillIdentificationType','$state'];

    function ReceivingClientDetailController(Modal, globalCompany, CommonMethods, $scope, $rootScope, $stateParams, previousState, entity, ReceivingClient, Company, House, FiscalTerritorialDivision, ElectronicBillIdentificationType, $state) {
        var vm = this;

        vm.receivingClient = {};
        vm.previousState = previousState.name;

        var id = $stateParams.houseId;
        vm.save = save;
        vm.houseNumber = $stateParams.houseNumber;
        vm.houseId = CommonMethods.decryptIdUrl(id + "");
        vm.neighborhoodList = [];
        vm.cantonList = [];
        vm.provinceList = [];
        vm.districtList = [];
        vm.isReady = false;

        vm.emailsPatterns = "/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}(;[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})*$/";


        ReceivingClient.getByHouseId({houseId: vm.houseId}, function (data) {
            vm.receivingClient = data;
            vm.receivingClient.identificationNumber = parseFloat(data.identificationNumber);
        }, function () {
            vm.receivingClient.internalCode = vm.houseNumber;
        });

        vm.delete = function (id) {
            Modal.confirmDialog("¿Está seguro que desea eliminar los datos del cliente?", "Si los elimina ya no se emitirán documentos electrónicos para este cliente", function () {
                ReceivingClient.delete({id: id},
                    function () {
                        vm.receivingClient = {};
                        Modal.toast("Datos eliminados automáticamente");
                    });
            });
        };

        function save() {
            Modal.confirmDialog("¿Está seguro que desea guardar los datos?", "Asegurese que los datos estén registrados correctamente para evitar errores al emitir los documentos electrónicos", function () {
                vm.isSaving = true;
                vm.receivingClient.houseId = vm.houseId;
                vm.receivingClient.companyId = globalCompany.getId();
                vm.receivingClient.provinceId = vm.receivingClient.province.id;
                vm.receivingClient.cantonId = vm.receivingClient.canton.id;
                vm.receivingClient.districtId = vm.receivingClient.district.id;
                vm.receivingClient.comercialName = vm.receivingClient.name;
                if (vm.receivingClient.neighborhood) {
                    vm.receivingClient.neighborhoodId = vm.receivingClient.neighborhood.id;
                }
                if (vm.receivingClient.id !== null) {
                    ReceivingClient.update(vm.receivingClient, onSaveSuccess, onSaveError);
                } else {
                    ReceivingClient.save(vm.receivingClient, onSaveSuccess, onSaveError);
                }
            }
            );

            function onSaveSuccess() {
                Modal.toast("Se guardaron los datos correctamente");
                $state.go("receiving-client");
            }

            function onSaveError() {
                Modal.toast("Ocurrio un error guardando los datos");
            }
        }

        loadAllElectronicBillIdentificationType();

        function loadAllElectronicBillIdentificationType() {
            ElectronicBillIdentificationType.query({}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.electronicBillIdentificationTypes = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAllFiscalTerritorialDivision();

        function loadAllFiscalTerritorialDivision() {
            FiscalTerritorialDivision.query({size: 8000}, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.fiscalTerritorialDivision = data;
                vm.filterProvince(data);
                if (vm.receivingClient.id) {
                    vm.filterCanton(vm.receivingClient.provinceId);
                    vm.filterDistrict(vm.receivingClient.cantonId);
                    vm.filterNeighborhood(vm.receivingClient.districtId);
                    vm.findFiscalTerriories(vm.receivingClient.provinceId, vm.receivingClient.cantonId, vm.receivingClient.districtId, vm.receivingClient.neighborhoodId);
                }else{
                    vm.isReady = true;
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.filterProvince = function (list) {
            var provinceList = [];
            for (var i = 0; i < list.length; i++) {
                if (list[i].territorialType === 'Provincia') {
                    provinceList.push(list[i]);
                }
            }
            vm.provinceList = provinceList;
        };

        vm.findFiscalTerriories = function (provinceId, cantonId, districtId, neighborhoodId) {
            for (var i = 0; i < vm.provinceList.length; i++) {
                if (vm.provinceList[i].id === provinceId) {
                    vm.receivingClient.province = vm.provinceList[i];
                }
            }
            for (var i = 0; i < vm.cantonList.length; i++) {
                if (vm.cantonList[i].id === cantonId) {
                    vm.receivingClient.canton = vm.cantonList[i];
                }
            }
            for (var i = 0; i < vm.districtList.length; i++) {
                if (vm.districtList[i].id === districtId) {
                    vm.receivingClient.district = vm.districtList[i];
                }
            }
            for (var i = 0; i < vm.neighborhoodList.length; i++) {
                if (vm.neighborhoodList[i].id === neighborhoodId) {
                    vm.receivingClient.neighborhood = vm.neighborhoodList[i];
                }
            }
            vm.isReady = true;
        };

        vm.filterCanton = function (provinceId) {
            var cantonList = [];
            for (var i = 0; i < vm.fiscalTerritorialDivision.length; i++) {
                if (vm.fiscalTerritorialDivision[i].belongsToId == provinceId && vm.fiscalTerritorialDivision[i].territorialType == 'Cantón') {
                    cantonList.push(vm.fiscalTerritorialDivision[i]);
                }
            }
            vm.cantonList = cantonList;
        };

        vm.filterDistrict = function (provinceId) {
            var districtList = [];
            for (var i = 0; i < vm.fiscalTerritorialDivision.length; i++) {
                if (vm.fiscalTerritorialDivision[i].belongsToId == provinceId && vm.fiscalTerritorialDivision[i].territorialType == 'Distrito') {
                    districtList.push(vm.fiscalTerritorialDivision[i]);
                }
            }
            vm.districtList = districtList;
        };


        vm.filterNeighborhood = function (provinceId) {
            var neighborhoodList = [];
            for (var i = 0; i < vm.fiscalTerritorialDivision.length; i++) {
                if (vm.fiscalTerritorialDivision[i].belongsToId == provinceId && vm.fiscalTerritorialDivision[i].territorialType == 'Barrio o Poblado') {
                    neighborhoodList.push(vm.fiscalTerritorialDivision[i]);
                }
            }
            vm.neighborhoodList = neighborhoodList;
        };

        var unsubscribe = $rootScope.$on('aditumApp:receivingClientUpdate', function (event, result) {
            vm.receivingClient = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
