(function () {
    'use strict';

    angular
        .module('aditumApp')
        .factory('WSGate', WSGate);

    WSGate.$inject = ['StompManager', 'StompManagerSecond', 'globalCompany'];

    function WSGate(StompManager, StompManagerSecond, globalCompany) {
        const SUBSCRIBE_TRACKER_URL = '/topic/gate-opened/';
        const SEND_ACTIVITY_URL = '/topic/gate-open/';

        const SUBSCRIBE_TRACKER_VISITOR_URL = '/topic/gate-opened-visitor/';
        const SEND_ACTIVITY_VISITOR_URL = '/topic/gate-open-visitor/';

        const SUBSCRIBE_TRACKER_QR_GATE_URL = '/topic/sendQrOpenRequest/';
        const SEND_ACTIVITY_QR_REQUEST_OPEN_URL =  '/topic/sendQrOpenRequest/';

        const SUBSCRIBE_TRACKER_CLEAR_QR_GATE_URL = '/topic/sendClearQrOpenRequest/';
        const SEND_ACTIVITY_CLEAR_QR_REQUEST_OPEN_URL =  '/topic/sendClearQrOpenRequest/';

        const service = {
            receive: receive,
            sendActivity: sendActivity,
            subscribe: subscribe,
            unsubscribe: unsubscribe,
            receiveVisitorGate: receiveVisitorGate,
            sendActivityVisitor: sendActivityVisitor,
            receiveQrRequestGate: receiveQrRequestGate,
            receiveQrCleanRequestGate: receiveQrCleanRequestGate,
            sendClearQrGateRequest: sendClearQrGateRequest,
            sendQrGateRequest: sendQrGateRequest,
        };

        return service;

        function receive() {
            return StompManager.getListener(SUBSCRIBE_TRACKER_URL + globalCompany.getId());
        }

        function receiveVisitorGate() {
            return StompManager.getListener(SUBSCRIBE_TRACKER_VISITOR_URL + globalCompany.getId());
        }

        function sendActivity(entity) {
            StompManager.send(SEND_ACTIVITY_URL + globalCompany.getId(), entity);
        }

        function receiveQrRequestGate() {
            return StompManagerSecond.getListener(SUBSCRIBE_TRACKER_QR_GATE_URL + globalCompany.getId());
        }

        function receiveQrCleanRequestGate() {
            return StompManagerSecond.getListener(SUBSCRIBE_TRACKER_CLEAR_QR_GATE_URL + globalCompany.getId());
        }

        function sendQrGateRequest(entity) {
            StompManagerSecond.send(SEND_ACTIVITY_QR_REQUEST_OPEN_URL + globalCompany.getId(), entity);
        }

        function sendClearQrGateRequest(entity) {
            StompManagerSecond.send(SEND_ACTIVITY_CLEAR_QR_REQUEST_OPEN_URL + globalCompany.getId(), entity);
        }

        function sendActivityVisitor(entity) {
            StompManager.send(SEND_ACTIVITY_VISITOR_URL + globalCompany.getId(), entity);
        }

        function subscribe(companyId) {
            unsubscribe(companyId);
            StompManager.subscribe(SUBSCRIBE_TRACKER_URL + companyId);
            StompManager.subscribe(SUBSCRIBE_TRACKER_VISITOR_URL + companyId);
            StompManagerSecond.subscribe(SUBSCRIBE_TRACKER_QR_GATE_URL + companyId);
            StompManagerSecond.subscribe(SUBSCRIBE_TRACKER_CLEAR_QR_GATE_URL + companyId);
        }

        function unsubscribe(companyId) {
            StompManager.unsubscribe(SUBSCRIBE_TRACKER_URL + companyId);
            StompManager.unsubscribe(SUBSCRIBE_TRACKER_VISITOR_URL + companyId);
            StompManagerSecond.unsubscribe(SUBSCRIBE_TRACKER_QR_GATE_URL + companyId);
            StompManagerSecond.subscribe(SUBSCRIBE_TRACKER_CLEAR_QR_GATE_URL + companyId);
        }
    }
})();


